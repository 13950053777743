import { openDB, DBSchema } from 'idb'

interface Database extends DBSchema {
  news: {
    key: string
    value: NewsItem
  }
}

export default openDB<Database>('main', 1, {
  upgrade(db) {
    db.createObjectStore('news', {
      keyPath: 'id',
      autoIncrement: true,
    })
  }
})
