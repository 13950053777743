import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { newsSelector } from 'state'
import styles from './styles.module.scss'



const News: FC = () => {
  const news = useRecoilValue(newsSelector)

  return (
    <>
      <h2>News</h2>
      <div className={styles.wrapper}>
        {news.length ? news.map((item, i) =>
          <div
            key={i}
            className={styles.item}
            style={{ backgroundImage: item.images && `url(${item.images[0]})` }}
            onClick={() => open(item.link)}>
            <div className={styles.headline}>{item.title}</div>
          </div>
        ) : <div className={styles.item}>Loading...</div>}
      </div>
    </>
  )
}


export default News
