import { FC } from 'react'
import styles from 'app/index.module.scss'
import { Outlet } from 'react-router-dom'

const App: FC = () => {
  return (
    <>
      <header className={styles.header}>Companion</header>
      <main className={styles.main}>
        <Outlet />
      </main>
    </>
  )
}

export default App
