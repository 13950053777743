import database from 'database'
import { selector } from 'recoil'
import { get as getKeyVal } from 'idb-keyval'
import { fetchAndCacheLatestNews } from 'shared'

const dbState = selector({
  key: 'dbState',
  get: () => {
    return database
  }
})

export const newsSelector = selector({
  key: 'newsSelector',
  get: async ({ get }) => {
    const db = get(dbState)
    const lastUpdate = await getKeyVal<number>('last-news-update')
    if (lastUpdate === undefined || Date.now() - lastUpdate > 1000 * 60 * 10) {
      try {
        await fetchAndCacheLatestNews()
      } catch (e) {
        console.error('failed to fetch news', e)
      }
    }
    return db.getAll('news')
  }
})
