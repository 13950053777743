import { StrictMode, Suspense } from 'react'
import { render } from 'react-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'index.scss'

import App from 'app'
import Home from 'app/Home'
import { RecoilRoot } from 'recoil'

(async () => {
  try {
    const permission = await (navigator.permissions.query as any)({
      name: 'periodic-background-sync'
    }) as PermissionStatus
    if (permission.state === 'granted') {
      const registration = await navigator.serviceWorker.ready as any
      await registration.periodicSync.register('get-latest-news', {
        minInterval: 2 * 60 * 60 * 1000,
      })
    }
  } catch {
    console.log('Periodic Sync could not be registered!')
  }
})()


render(
  <StrictMode>
    <Suspense fallback={null}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()
reportWebVitals()
