import { FC } from 'react'
import ActionBar from 'components/ActionBar'
import News from 'components/News'

const Home: FC = () => {
  return (
    <>
      <ActionBar />
      <News />
    </>
  )
}

export default Home
