import { useEffect } from 'react'
import styles from './styles.module.scss'

const ActionBar = () => {

  const barcodeDetectorSupported = 'BarcodeDetector' in window

  useEffect(() => {
    /*(async () => {
      await navigator.mediaDevices.getUserMedia({ video: true })
    })()*/

  }, [])

  return (
    <div className={styles.root}>
      <input className={styles.input} type="text" />
      {barcodeDetectorSupported && (
        <button className={styles.qrButton}>
          <span className="material-icons">
            qr_code_scanner
          </span>
        </button>
      )}

    </div>
  )
}

export default ActionBar
