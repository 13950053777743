import database from 'database'
import { set } from 'idb-keyval'

export async function fetchAndCacheLatestNews() {
  const news = await fetch('https://companion-yipez.ondigitalocean.app/news').then(res => res.json()) as NewsItem[]
  const db = await database
  db.clear('news')
  await Promise.all(news.map(item => db.add('news', item)))
  await set('last-news-update', Date.now())
}
